// REACT STUFF
import React, { Suspense } from "react";
import { useEffect, useState, useContext, Component } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import axios from "axios";

// AUTH CONTEXT
import { AuthContext } from "../context/AuthContext.js";

import GoogleConnect from "../components/GoogleConnect.js";

// LIBS
import { useTranslation } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import "../i18n.js";
import Modal from "react-modal";

//Components
const Footer = React.lazy(() => import("../components/footer.js"));

import Loader from "../components/Loader.js";
const Accordion = React.lazy(() => import("../components/Accordion.js"));
import TypingText from "../components/typingText.js";

// LIB
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// CSS STYLES
import "./QuiEstAnda.css";
const Testimonials = React.lazy(() => import("../components/Testimonials.js"));

function QuiEstAnda() {
  const context = useContext(AuthContext);

  const [isOpenLogin, setIsOpenLogin] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [results, setResults] = useState(null);
  const [resultsLinkedin, setResultsLinkedin] = useState(null);
  const [resultsReddit, setResultsReddit] = useState(null);
  const [resultsPinterest, setResultsPinterest] = useState(null);
  const [resultsYoutube, setResultsYoutube] = useState(null);
  const [resultsTiktok, setResultsTiktok] = useState(null);
  const [resultsInstagram, setResultsInstagram] = useState(null);
  const [resultsGoogle, setResultsGoogle] = useState(null);
  const [resultsGoogleNews, setResultsGoogleNews] = useState(null);
  const [resultsMedium, setResultsMedium] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [isMonthly, setIsMonthly] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const [content, setContent] = useState(null);
  const contents = `${process.env.REACT_APP_STRAPI_URL}`;

  const { t, i18n } = useTranslation();

  const openModal = () => {
    setModalIsOpen(true);
  };
  const closeModal = () => {
    setModalIsOpen(false);
  };

  const redirectToRegister = () => {
    window.location.href = "/register";
  };

  const openModalWithContent = (content) => {
    setModalContent(content);
    openModal();
  };

  const toggleModalLogin = () => {
    setIsOpenLogin(!isOpenLogin);
  };

  const handleToggle = () => {
    setIsMonthly(!isMonthly);
  };

  const formatCount = (count) => {
    if (count <= 100) {
      return count.toString();
    } else if ((count) => 1000) {
      return 100;
    }
  };

  useEffect(() => {
    axios
      .get(`${contents}/api/about-us?populate=*`, {
        headers: {
          Authorization: `Bearer ${process.env.REACT_APP_BEARER_CONTENT}`,
        },
      })
      .then((response) => {
        // console.log("response.data.data[0].attributes", response.data.data[0]);
        setContent(response.data.data.attributes);
      })
      .catch((error) => {
        console.error("Il y a eu une erreur !", error);
      });
  }, []);

  if (!content) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {/* <LoginModal isOpen={isOpenLogin} hide={toggleModalLogin} /> */}

      <div className="globalContainer">
        <main id="QuiEstAda">
          <div className="mainBgAda">
            <div></div>
          </div>
          <div className="wrap vacL">
            <h1 className="mb50">
              <span dangerouslySetInnerHTML={{ __html: content.Title }}></span>
              <br />
              <img className="vectorHeroADA" src="/assets/vector_hero.png" />
            </h1>
            <h4>
              <span
                dangerouslySetInnerHTML={{ __html: content.subTitle }}
              ></span>
            </h4>
            <br />
            <br />
          </div>
        </main>

        <section id="QuiEstDelhia">
          <div className="wrapL">
            <div className="founder-section">
              <div className="image-container">
                {content.image1 && content.image1.data && (
                  <img
                    className="founder-image"
                    src={`${contents}${content.image1.data.attributes.url}`}
                    alt={
                      content.image1.data.attributes.alternativeText ||
                      "Délhia Camitsis"
                    }
                  />
                )}

                <span
                  className="vignetteBlancheSectionAda"
                  dangerouslySetInnerHTML={{ __html: content.DescriptionIMG1 }}
                ></span>
              </div>
              <div className="text-containerQuiest">
                <h2 dangerouslySetInnerHTML={{ __html: content.TitleH1 }}></h2>
                <p
                  className="paragraphQuiest"
                  dangerouslySetInnerHTML={{ __html: content.TitleDescription }}
                ></p>
              </div>
            </div>
          </div>
        </section>

        <section id="QuiEstDelhia2">
          <div className="wrapL">
            <div className="founder-section">
              <div className="text-containerQuiest2">
                <p
                  className="paragraphQuiest"
                  dangerouslySetInnerHTML={{ __html: content.Description2 }}
                ></p>
              </div>
              <div className="image-container">
                {content.image2 && content.image2.data && (
                  <img
                    className="founder-image"
                    src={`${contents}${content.image2.data.attributes.url}`}
                    alt={
                      content.image2.data.attributes.alternativeText ||
                      "Délhia Camitsis"
                    }
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <section id="highlightEventAda">
          <div className="highlight-contentAda">
            <h2 className="highlight-title">{content.VignetteTitle}</h2>
            <p
              className="small-text"
              dangerouslySetInnerHTML={{ __html: content.VignetteDescription }}
            ></p>
            <div className="buttonStoreAda">
              {content.appleButton && content.appleButton.data && (
                <a target="_blank" href={content.buttonAppleStore}>
                  <img
                    className="buttonStore"
                    src={`${contents}${content.appleButton.data.attributes.url}`}
                    alt="Apple Store"
                  />
                </a>
              )}

              {content.GooglePlayButton && content.GooglePlayButton.data && (
                <a target="_blank" href={content.buttonGooglePlay}>
                  <img
                    className="buttonStore"
                    src={`${contents}${content.GooglePlayButton.data.attributes.url}`}
                    alt="Google Play"
                  />
                </a>
              )}
            </div>
          </div>
          <div>
            {content.Phone && content.Phone.data && (
              <img
                className="phoneHHighlight"
                src={`${contents}${content.Phone.data.attributes.url}`}
                alt="Iphone Appercu"
              />
            )}
          </div>
        </section>

        <section id="histoireDeAnda">
          <div className="wrapL">
            <div className="history-section">
              <div className="image-container">
                {content.logoGrand && content.logoGrand.data && (
                  <img
                    className="logo-image"
                    src={`${contents}${content.logoGrand.data.attributes.url}`}
                    alt="AN-DA Logo"
                  />
                )}
              </div>
              <div className="text-container">
                <h2>{content.TitleH12}</h2>
                <h3>{content.TitleH1Sub}</h3>
                <p
                  dangerouslySetInnerHTML={{
                    __html: content.TitleH12Description,
                  }}
                ></p>
                <p className="quote">{content.Quote}</p>
                <p>{content.FinDescription}</p>
              </div>
            </div>
          </div>
        </section>

        <section id="nosValeurs">
          <div className="wrap">
            <h2>{content.NosValeurs}</h2>
            <div className="values-container">
              <div className="value-item">
                <img
                  src="/assets/social_icon.png"
                  alt="Social Icon"
                  className="value-icon"
                />
                <h3>{content.Social}</h3>
                <p>{content.SocialDescription}</p>
              </div>
              <div className="value-item">
                <img
                  src="/assets/accessibility-icon.png"
                  alt="Accessibility Icon"
                  className="value-icon"
                />
                <h3>{content.Accessibilite}</h3>
                <p>{content.QualiteDescription}</p>
              </div>
              <div className="value-item">
                <img
                  src="/assets/quality-icon.png"
                  alt="Quality Icon"
                  className="value-icon"
                />
                <h3>{content.Qualite}</h3>
                <p>{content.QualiteDescription}</p>
              </div>
              <div className="value-item">
                <img
                  src="/assets/equality-icon.png"
                  alt="Equality Icon"
                  className="value-icon"
                />
                <h3>{content.Egalite}</h3>
                <p>{content.EgaliteDescription}</p>
              </div>
              <div className="value-item">
                <img
                  src="/assets/passion-icon.png"
                  alt="Passion Icon"
                  className="value-icon"
                />
                <h3>{content.Passion}</h3>
                <p>{content.PassionDescription}</p>
              </div>
            </div>
            <div className="cta-button disabled">
              <button>{content.DiscoverAll}</button>
              <span className="tooltip">Bientôt disponible</span>
            </div>
          </div>
        </section>

        <section id="nosMissions">
          <div className="wrapL">
            <div className="mission-content">
              <div className="text-content">
                <h2>{content.OurMissions}</h2>
                <p>{content.OurMissionDescription}</p>
                <ul className="mission-list">
                  <li>
                    <img
                      src="/assets/calendar-icon.png"
                      alt="Calendrier Icon"
                      className="mission-icon"
                    />
                    {content.Mission1}
                  </li>
                  <li>
                    <img
                      src="/assets/connect-icon.png"
                      alt="Connexion Icon"
                      className="mission-icon"
                    />
                    {content.Mission2}
                  </li>
                  <li>
                    <img
                      src="/assets/diversity-icon.png"
                      alt="Diversité Icon"
                      className="mission-icon"
                    />
                    {content.Mission3}
                  </li>
                  <li>
                    <img
                      src="/assets/access-icon.png"
                      alt="Accès Icon"
                      className="mission-icon"
                    />
                    {content.Mission4}
                  </li>
                  <li>
                    <img
                      src="/assets/community-icon.png"
                      alt="Communauté Icon"
                      className="mission-icon"
                    />
                    {content.Mission5}
                  </li>
                  <li>
                    <img
                      src="/assets/joy-icon.png"
                      alt="Joie Icon"
                      className="mission-icon"
                    />
                    {content.Mission6}
                  </li>
                </ul>
              </div>
              <div className="image-content">
                {content.ImageMission && content.ImageMission.data && (
                  <img
                    alt="Mission Image"
                    className="mission-image"
                    src={`${contents}${content.ImageMission.data.attributes.url}`}
                  />
                )}
              </div>
            </div>
          </div>
        </section>

        <Suspense fallback={<div>{t("loading")}...</div>}>
          <Footer />
        </Suspense>
      </div>
    </>
  );
} // END HOMEPAGE

export default QuiEstAnda;
